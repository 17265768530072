export default {
  table : {
    tableClass     : 'table table-striped table-bordered',
    ascendingIcon  : 'fas fa-sort-up',
    descendingIcon : 'fas fa-sort-down',
    handleIcon     : 'glyphicon glyphicon-menu-hamburger',
  },
  pagination : {
    wrapperClass  : "pagination pull-right",
    activeClass   : "btn-primary",
    disabledClass : "disabled",
    pageClass     : "btn btn-border",
    linkClass     : "btn btn-border",
    icons         : {
      first : "",
      prev  : "",
      next  : "",
      last  : "",
    },
  },
}
